'use client';

import React from 'react';
import Checkout from 'components/padi-ui/checkout';
import Redirect from 'helpers/redirect';
import { useAccount } from 'frontastic/hooks';
import { TasticProps } from '../types';

const CheckoutTastic = () => {
  const { loggedIn } = useAccount();
  if (!loggedIn) return <Redirect target="/login?lvp=checkout" />;

  //Fully customized checkout
  return <Checkout />;
};

export default CheckoutTastic;
