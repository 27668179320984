import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';

export interface Props {
  className?: string;
}

const Secure: React.FC<Props> = ({ className }) => {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  return (
    <div
      className={`my-24 hidden border-b border-b-neutral-400 bg-neutral-200 pb-24 md:px-24 lg:block lg:px-40 ${className}`}
    >
      <p className="text-22 font-bold capitalize">
        {formatCheckoutMessage({ id: 'secure.checkout', defaultMessage: 'Secure checkout' })}
      </p>
    </div>
  );
};

export default Secure;
